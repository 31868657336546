<script>
import Layout from "../../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { getHseCriteriasFromTypeApi } from "@/api/common";
import { required, maxLength } from "vuelidate/lib/validators";
import Swal from "sweetalert2";

export default {
  page: {
    title: "Critères HSE",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader },
  import: { getHseCriteriasFromTypeApi },
  data() {
    return {
      listsDropdownElems: [],
      submitted: false,
      currentSelectedItem: {},
      singleDropdownTypeElems: [],
      showNewTypeModal: false,
      singleDropdownType: {
        name: "",
        description: "",
        type: "",
      },
      newDropDownEntryForm: {
        name: "",
        description: "",
      },
      title: "Critères HSE",

      items: [
        {
          text: "Paramètres",
          to: {name: 'base.settings.index'}
        },
        {
          text: "Critères HSE",
          active: true,
        },
      ],
    };
  },

  methods: {
    clearForm(){
      this.submitted = false;
      this.newDropDownEntryForm.name = "";
      this.newDropDownEntryForm.description = "";
      this.newDropDownEntryForm.elemId = null;
    },
    fetchDropDownsElements() {
      this.$http.post("/system/settings/getHseCriteriasTitles").then((res) => {
        this.listsDropdownElems = res.data.original.listsElems;
      });
    },

    saveNewDropdownEntry() {
      // validate input
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/saveHseCriteria", {
            data: this.newDropDownEntryForm,
            type: this.currentSelectedItem.id,
          })
          .then((res) => {
            loader.hide();
            var status = res.data.status;
            switch (status) {
              case 200:
                this.$toast.success(res.data.msg);
                this.clearForm();
                this.showNewTypeModal = false;
                this.submitted = false;
                this.getHseCriteriasFromType(
                  res.data.record.criteria_type
                );
                break;

              case 500:
                this.$toast.warning(res.data.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
      }
    },

    getHseCriteriasFromType(type) {
      var loader = this.$loading.show();
      getHseCriteriasFromTypeApi(type).then((res) => {
        loader.hide();
        this.singleDropdownTypeElems = res.data.list;
      }).catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
    },

    editSingleType(elem){
        var loader = this.$loading.show();
        this.$http
          .post("/system/settings/getSinglHseCriteriaDetails", {
            criteriaId: elem.uuid,
          })
          .then((res) => {
            loader.hide();
            var status = res.data.original.status;
            var elem = res.data.original.rec;
            switch (status) {
              case 200:
                this.newDropDownEntryForm.name = elem.name;
                this.newDropDownEntryForm.elemId = elem.uuid;
                this.showNewTypeModal = true;
                break;

              case 500:
                this.$toast.warning(res.data.original.msg);
                break;
            }
          })
          .catch((error) => {
            loader.hide();
            this.$toast.error(error.message);
          })
          .finally(() => {});
    },

    deleteSingleType(item) {
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le critère :" + item.name + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          var loader = this.$loading.show();
          this.$http
            .post("/system/settings/deleteSinglHseCriteria", {
              itemId: item.uuid,
            })
            .then((res) => {
              loader.hide();
              var status = res.data.status;
              switch (status) {
                case 200:
                  this.$toast.success(res.data.msg);
                  this.getHseCriteriasFromType(item.criteria_type);
                  break;

                case 500:
                  this.$toast.warning(res.data.msg);
                  break;
              }
            })
            .catch((error) => {
              loader.hide();

              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },

    getDropdownListValues(elem) {
      this.currentSelectedItem = elem;
      var type = elem.id;
      var loader = this.$loading.show();
      getHseCriteriasFromTypeApi(type).then((res) => {
        loader.hide();
        this.singleDropdownType.name = elem.name;
        this.singleDropdownType.description = elem.description;
        if (res.data.original) {
          this.singleDropdownTypeElems = res.data.original.list;
        } else {
          this.singleDropdownTypeElems = res.data.list;
        }
      });
    },
  },
  validations: {
    newDropDownEntryForm: {
      name: {
        required,
        maxLength: maxLength(64),
      },
    },
  },
  mounted() {
    this.fetchDropDownsElements();
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-3">
        <div class="card" style="height: 400px; overflow-y: scroll;">
          <div class="card-body">
            <h4 class="card-title mb-4"></h4>
            <div class="table-responsive mb-0">
              <table class="table mb-2" >
                <tbody>
                  <h5>Critères:</h5>
                  <tr v-for="(elem, index) in listsDropdownElems" v-bind:key="index" :class="currentSelectedItem.id == elem.id ? 'activeTr' : ''" >
                    <td scope="row">
                      <a
                        href="javascript:void(0)"
                        @click.prevent="getDropdownListValues(elem)"
                        >{{ elem.name }}</a
                      >
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-9" >
        <div class="card" style="height: 400px; overflow-y: scroll;">
          <div class="card-body">
            <h4 class="card-title mb-4">
              Critères: {{ singleDropdownType.name }}
              <button
                v-if="singleDropdownType.name != ''"
                @click="showNewTypeModal = true"
                class="btn btn-primary float-right"
              >
                <i class="fas fa-plus"></i> Ajouter
              </button>
            </h4>
            <p class="text-muted mb-4">{{ singleDropdownType.description }}</p>
            <div class="table-responsive mb-0">
              <table class="table table-nowrap table-hover ">
                <thead>
                  <tr>
                    <th scope="col">ID</th>
                    <th scope="col">Désignation</th>
                    <th scope="col">Crée par</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    
                    v-for="(item, index) in singleDropdownTypeElems"
                    v-bind:key="item.id"
                  >
                    <th scope="row">{{ index + 1 }}</th>
                    <td>{{ item.name }}</td>
                    <td>
                      <span
                        class="badge badge-pill badge-soft-success font-size-11 badge-soft-danger"
                        style="color: #f46a6a;"
                        v-if="item.systeme"
                        >Système</span
                      >
                      <span
                        v-else
                        class="badge badge-pill badge-soft-success font-size-11 badge-soft-success"
                        style="color: #34c38f"
                        >Utilisateur</span
                      >
                    </td>
                    <td>
                      <span v-if="item.systeme">N/A</span>
                      <span v-else>
                        <a @click="editSingleType(item)" class="text-success" href="javascript:void(0)">
                          <i class="fas fa-edit"></i>
                        </a>
                        <a
                          @click="deleteSingleType(item)"
                          href="javascript:void(0)"
                          class="text-danger"
                        >
                          <i class="fas fa-trash ml-2"></i>
                        </a>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal
      v-model="showNewTypeModal"
      :title="`Ajouter un Critère : ${singleDropdownType.name}`"
      @close="clearForm"
    >
      <form @submit.prevent="saveNewDropdownEntry">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="">Désignation</label>
              <input
                type="text"
                class="form-control"
                v-model="newDropDownEntryForm.name"
                :class="{
                  'is-invalid':
                    submitted && $v.newDropDownEntryForm.name.$error,
                }"
              />
              <div
                v-if="submitted && $v.newDropDownEntryForm.name.$error"
                class="invalid-feedback"
              >
                <span v-if="!$v.newDropDownEntryForm.name.required"
                  >Champs Obligatoire.</span
                >
                <br />
                <span v-if="!$v.newDropDownEntryForm.name.maxLength"
                  >La taille minimum du champs est: <b>64</b> Caractères.</span
                >
              </div>
            </div>
          </div>
          
        </div>
        <br />

        <hr />
        <div class="row">
          <div class="col-md-12">
            <button class="btn btn-primary float-right">
              <i class="fas fa-save"></i> Enregistrer
            </button>
          </div>
        </div>
      </form>
    </Modal>
  </Layout>
</template>
